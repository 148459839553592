<template>
	<v-card v-if="questionnaire" flat class="wr_grey_1">
		<v-card-text>
			<!-- If questionnaire is in draft mode then show form -->
			<v-form ref="chooseThemeQuestionnaireForm" v-if="isQuestionnaireDrafted">
				<FormGenerator></FormGenerator>
			</v-form>
			<!-- else show only answers of form -->
			<FormResult v-else></FormResult>
		</v-card-text>

		<!-- Show finish source documentation  -->
		<StageFinishDialog
			v-if="dialog"
			v-model="dialog"
			:dialog="dialog"
			current_step="app.choose_theme.title"
			@close="dialog = false"
		></StageFinishDialog>
	</v-card>
</template>

<script>
import FormGenerator from "@/components/plugins/formGenerator/Index";
import FormResult from "@/components/plugins/formResult/Index";
import { formValidationMixin } from "@/mixins/questionnaire/formValidaionMixin.js";
import { mapState } from "vuex";
import StageFinishDialog from "@/components/research/modules/_common/stages/FinishDialog";
import { questionnaireHelper } from "@/utils/helpers/questionnaire.js";
import { notificationWriteMixin } from "@/mixins/notificationsMixin";

export default {
	name: "ChooseThemeSummaryPage",

	mixins: [formValidationMixin, notificationWriteMixin],

	data() {
		return {
			dialog: false,
		};
	},

	components: {
		FormGenerator,
		FormResult,
		StageFinishDialog,
	},

	created() {
		this.readyToListenEvents();
	},

	mounted() {
		this.getQuestions(this.$defines.QUESTIONNAIRE.q10);
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("submit-questionnaire", async (payload) => {
				try {
					await this.submitQuestionnaire("chooseThemeQuestionnaireForm");
				} catch (error) {
					this.$announce.error(error);
				}
			});
			this.$eventBus.$on("redirect-to-previous", async (payload) => {
				this.$router.push({
					name: "ChooseTheme",
				});
			});
			this.$eventBus.$on("finish-choose-field", async (payload) => {
				await this.onFinishProcess();
			});
		},

		async notifyToMentor() {
			// Get the research question
			let research_theme = await questionnaireHelper.getChosenTheme();
			// If the answer is given, send it to mentor.
			if (research_theme) {
				let response = await this.$store.dispatch("notification/send", {
					recipient: "mentor",
					msg_title: this.$t("app.my_research_theme"),
					msg_content: research_theme.answer,
				});
				// Now, Write this message to firebase
				await this.writeNotification(response);
			}
		},

		async onFinishProcess() {
			try {
				this.$loader.start();

				// Send the research question to mentor if user has mentor
				if (this.userHasMentor) {
					await this.notifyToMentor();
				}

				// Open the finish dialog
				this.dialog = true;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("submit-questionnaire");
		this.$eventBus.$off("redirect-to-previous");
	},
};
</script>
